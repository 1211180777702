.form {
	width: 480px;
	margin: 25px auto;
	padding: 0 24px;
}

.dateFields {
	display: flex;
	justify-content: left;
}

.leftDataPicker {
	margin-right: 30px;
}

.label {
	margin-right: 30px;
}

.autoprolong {
	display: flex;
	margin: 30px 0;
}

.autoprolongTitle {
	margin-bottom: 10px;
}

.autoprolongRadio {
	margin-left: 30px;
}

.buttonsWrat {
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	width: fit-content;
}

.button {
	margin: 0 10px;
}

.divider {
  margin: 1px 0;
}

.iconWrap {
  padding: 8px;
  cursor: pointer;
}

.icon {
  text-align: center;
  margin: 0 auto;
}