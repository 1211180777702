@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-MediumItalic.eot');
  src: local('Roboto Medium Italic'), local('/static/fonts/Roboto-MediumItalic'),
  url('/static/fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-MediumItalic.woff') format('woff'),
  url('/static/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('/static/fonts/Roboto-Italic'),
  url('/static/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Italic.woff') format('woff'),
  url('/static/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('/static/fonts/Roboto-Bold'),
  url('/static/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Bold.woff') format('woff'),
  url('/static/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Regular.eot');
  src: local('Roboto'), local('/static/fonts/Roboto-Regular'),
  url('/static/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Regular.woff') format('woff'),
  url('/static/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('/static/fonts/Roboto-Medium'),
  url('/static/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Medium.woff') format('woff'),
  url('/static/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-BoldItalic.eot');
  src: local('Roboto Bold Italic'), local('/static/fonts/Roboto-BoldItalic'),
  url('/static/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-BoldItalic.woff') format('woff'),
  url('/static/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-ThinItalic.eot');
  src: local('Roboto Thin Italic'), local('/static/fonts/Roboto-ThinItalic'),
  url('/static/fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-ThinItalic.woff') format('woff'),
  url('/static/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Black.eot');
  src: local('Roboto Black'), local('/static/fonts/Roboto-Black'),
  url('/static/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Black.woff') format('woff'),
  url('/static/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Light.eot');
  src: local('Roboto Light'), local('/static/fonts/Roboto-Light'),
  url('/static/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Light.woff') format('woff'),
  url('/static/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-LightItalic.eot');
  src: local('Roboto Light Italic'), local('/static/fonts/Roboto-LightItalic'),
  url('/static/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-LightItalic.woff') format('woff'),
  url('/static/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-BlackItalic.eot');
  src: local('Roboto Black Italic'), local('/static/fonts/Roboto-BlackItalic'),
  url('/static/fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-BlackItalic.woff') format('woff'),
  url('/static/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/fonts/Roboto-Thin.eot');
  src: local('Roboto Thin'), local('/static/fonts/Roboto-Thin'),
  url('/static/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/Roboto-Thin.woff') format('woff'),
  url('/static/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
