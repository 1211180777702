@import '../../globalStyles/colors.scss';

.mainLayout {
  background-color: $lightslategray;
}

.mainContent {
  min-height: calc(100vh - 140px);
  padding: 10px 15px 15px 15px;
  margin-top: 70px;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@include scrollbars(.2em, $darkslategray);
