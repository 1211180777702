.select {
  width: 100%!important;
}

.option {
	display: flex;
	justify-content: space-between;
}

.optionName {
	width: 100%!important;
}