.imgWrap {
	margin: 5px 10px 0 0;
	width: 200px;
  .ant-upload-select-picture-card {
    width: 100%!important;
    height: 100%!important;
    margin-bottom: 0;
  }
  .ant-upload {
    padding: 0px!important;
    margin: 0px!important
	}
	
	.ant-upload-select-picture-card {
		min-width: 120px !important;
    min-height: 180px !important;
	}
}
