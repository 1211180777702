@import '../../../common/globalStyles/colors.scss';

.wrap {
	box-shadow: $boxShadow;
	margin-bottom: 30px;
	padding-bottom: 1px;
}

.tagsView {
	border-bottom: 1px solid $lightslategray;
	padding: 10px;
}

.selectWrap {
	width: 284px;
	margin: 0 auto;
}

.spinWrap {
	display: flex;
	justify-content: center;
	padding: 24px 10px 18px 10px;
}