@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nova+Slim&display=swap);
body {
  font-family: 'Roboto', sans-serif; }

.ant-popover {
  z-index: 100 !important; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-MediumItalic.eot");
  src: local("Roboto Medium Italic"), local("/static/fonts/Roboto-MediumItalic"), url("/static/fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-MediumItalic.woff") format("woff"), url("/static/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Italic.eot");
  src: local("Roboto Italic"), local("/static/fonts/Roboto-Italic"), url("/static/fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Italic.woff") format("woff"), url("/static/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Bold.eot");
  src: local("Roboto Bold"), local("/static/fonts/Roboto-Bold"), url("/static/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Bold.woff") format("woff"), url("/static/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Regular.eot");
  src: local("Roboto"), local("/static/fonts/Roboto-Regular"), url("/static/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Regular.woff") format("woff"), url("/static/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Medium.eot");
  src: local("Roboto Medium"), local("/static/fonts/Roboto-Medium"), url("/static/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Medium.woff") format("woff"), url("/static/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-BoldItalic.eot");
  src: local("Roboto Bold Italic"), local("/static/fonts/Roboto-BoldItalic"), url("/static/fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-BoldItalic.woff") format("woff"), url("/static/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-ThinItalic.eot");
  src: local("Roboto Thin Italic"), local("/static/fonts/Roboto-ThinItalic"), url("/static/fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-ThinItalic.woff") format("woff"), url("/static/fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Black.eot");
  src: local("Roboto Black"), local("/static/fonts/Roboto-Black"), url("/static/fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Black.woff") format("woff"), url("/static/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Light.eot");
  src: local("Roboto Light"), local("/static/fonts/Roboto-Light"), url("/static/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Light.woff") format("woff"), url("/static/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-LightItalic.eot");
  src: local("Roboto Light Italic"), local("/static/fonts/Roboto-LightItalic"), url("/static/fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-LightItalic.woff") format("woff"), url("/static/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-BlackItalic.eot");
  src: local("Roboto Black Italic"), local("/static/fonts/Roboto-BlackItalic"), url("/static/fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-BlackItalic.woff") format("woff"), url("/static/fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("/static/fonts/Roboto-Thin.eot");
  src: local("Roboto Thin"), local("/static/fonts/Roboto-Thin"), url("/static/fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("/static/fonts/Roboto-Thin.woff") format("woff"), url("/static/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

.header_header__86XDc {
  background: #464742;
  padding: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 64px; }

.header_menu__1KWVb {
  background: #464742;
  line-height: 62px;
  color: #ffffff;
  border-bottom: 0; }

.header_linkName__1-0Kj {
  color: #ffffff; }

.appLayout_mainLayout__3UgEB {
  background-color: #e4e6e7; }

.appLayout_mainContent__33e17 {
  min-height: calc(100vh - 140px);
  padding: 10px 15px 15px 15px;
  margin-top: 70px; }

::-webkit-scrollbar {
  width: 0.2em;
  height: 0.2em; }

::-webkit-scrollbar-thumb {
  background: #464742; }

::-webkit-scrollbar-track {
  background: #a3a3a1; }

body {
  scrollbar-face-color: #464742;
  scrollbar-track-color: #a3a3a1; }

.footer_footerLayout__X1dmm {
  background-color: #464742; }

.footer_footer__1dJ3- {
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  margin-top: 4px; }

.inputField_suffixIco__jSN-D {
  color: red; }

.inputFieldTitle_inputWrapper__j-Mc_ {
  margin-bottom: 5px; }

.inputFieldTitle_errorMassage__3t6T9 {
  margin-bottom: 5px; }

.inputFieldTitle_asterisk__2soxI {
  color: red; }

.inputField_suffixIco__18vdA {
  color: red; }

.breadcrumbs_breadcrumbs__23qy2 {
  margin-bottom: 10px; }
  .breadcrumbs_breadcrumbs__23qy2 ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .breadcrumbs_breadcrumbsOne__1Qpe6 {
    border: 1px solid #464742;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 4px;
    display: inline-block; }
    .breadcrumbs_breadcrumbsOne__1Qpe6 li {
      float: left; }
      .breadcrumbs_breadcrumbsOne__1Qpe6 li:first-child a {
        padding-left: 1em; }
    .breadcrumbs_breadcrumbsOne__1Qpe6 a {
      padding: 5px 7px 5px 20px;
      float: left;
      color: #ffffff;
      font-weight: 600;
      position: relative;
      background-color: #464742;
      background-image: -webkit-gradient(linear, left top, right top, from(#53544e), to(#464742));
      background-image: linear-gradient(to right, #53544e, #464742); }
      .breadcrumbs_breadcrumbsOne__1Qpe6 a:hover {
        background: #53544e; }
      .breadcrumbs_breadcrumbsOne__1Qpe6 a:after, .breadcrumbs_breadcrumbsOne__1Qpe6 a:before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -1.2em;
        border-top: 1.1em solid transparent;
        border-bottom: 1.25em solid transparent;
        border-left: 1.2em solid;
        right: -1em; }
      .breadcrumbs_breadcrumbsOne__1Qpe6 a:after {
        z-index: 2;
        border-left-color: #464742; }
      .breadcrumbs_breadcrumbsOne__1Qpe6 a:before {
        border-left-color: #464742;
        right: -1.2em;
        z-index: 1; }
      .breadcrumbs_breadcrumbsOne__1Qpe6 a:hover::after {
        border-left-color: #53544e; }

.title_titleWrapper__1rTVT {
  padding: 0 0 15px 0; }

.title_title__ILDy9 {
  position: relative;
  font-weight: bold; }
  .title_title__ILDy9:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100px;
    border-bottom: 2px solid #464742; }

.selectField_select__1fOQQ {
  width: 100% !important; }

.start_wrap__1ay-l {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  width: 70%;
  height: 70vh;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.start_logo__1R2OZ {
  margin: 0 auto;
  display: block;
  width: 100px;
  height: 100px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.start_heading__Sj-oA {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  font-size: 5em;
  font-family: 'Nova Slim', cursive;
  border-top: 1px solid #464742;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.organizations_wrap__3Mij1 {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.organizations_listWrap__2Hrpq {
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.organizations_table__3EGuS {
  margin-top: 15px; }

.organizationList_wrap__25fiv {
  margin: 10px auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.organizationList_statistic__-W-y2 {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.organizationList_listWrap__1N6dL {
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.organizationList_table__3rpaK {
  margin-top: 15px; }

.organizationListFilter_filter__d9AvJ {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  padding: 15px 15px 1px 15px;
  border-radius: 5px; }

.modalWrap .ant-modal-content {
  margin-top: 18vh; }

.modalWrap .ant-modal-body {
  padding-top: 0px !important; }

.addMenus_form__4ND0h {
  padding-top: 20px; }

.refreshLocationsForm_form__2ah_i {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  margin: 10px auto;
  padding: 20px; }

.refreshLocationsForm_dateFields__16f3s {
  display: flex;
  justify-content: left; }

.refreshLocationsForm_leftDataPicker__1NQ4w {
  margin-right: 30px; }

.refreshLocationsForm_label__12xR3 {
  margin-right: 30px; }

.refreshLocationsForm_autoprolong__7up45 {
  display: flex;
  margin: 30px 0; }

.refreshLocationsForm_autoprolongTitle__Nsc3c {
  margin-bottom: 10px; }

.refreshLocationsForm_autoprolongRadio__1oqA8 {
  margin-left: 30px; }

.refreshLocationsForm_buttonsWrat__2On02 {
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.refreshLocationsForm_divider__1YpJs {
  margin: 1px 0; }

.refreshLocationsForm_iconWrap__2ooHQ {
  padding: 8px;
  cursor: pointer; }

.refreshLocationsForm_icon__2ggHS {
  text-align: center;
  margin: 0 auto; }

.menuSelectField_select__12uHU {
  width: 100% !important;
  height: 33px;
  overflow: hidden; }

.menuSelectField_option__2EeIR {
  display: flex;
  justify-content: space-between; }

.menuSelectField_optionName__3Z-wn {
  width: 100% !important; }

.menuSelectField_buttonsWrap__3S-DP {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.menuSelectField_button__1ylEP {
  margin-left: 5px; }

.modalWrap .ant-modal-content {
  margin-top: 18vh; }

.modalWrap .ant-modal-body {
  padding-top: 0px !important; }

.editMenu_form__3FwEj {
  padding-top: 20px; }

.refreshLocations_wrap__2XUL_ {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.refreshLocations_fromWrap__3jSAx {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.editOrganizationForm_form__32BqK {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  margin: 10px auto;
  padding: 20px; }

.editOrganizationForm_dateFields__3EEIW {
  display: flex;
  justify-content: left; }

.editOrganizationForm_leftDataPicker__2_Yc4 {
  margin-right: 30px; }

.editOrganizationForm_label__2SGvu {
  margin-right: 30px; }

.editOrganizationForm_autoprolong__1D_La, .editOrganizationForm_advertising__15OUj {
  display: flex;
  margin: 5px 0 10px;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #d9d9d9; }

.editOrganizationForm_autoprolongTitle__3O-Bu {
  margin-bottom: 10px; }

.editOrganizationForm_autoprolongRadio__1C1hu, .editOrganizationForm_advertisingRadio__VuyWB {
  margin-left: 30px; }

.editOrganizationForm_buttonsWrat__1YEyu {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.editOrganizationForm_button__gyV8m {
  margin: 0 10px; }

.editAdministratorForm_form__3dwW8 {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  margin: 10px auto;
  padding: 20px; }

.editAdministratorForm_dateFields__3_bBY {
  display: flex;
  justify-content: left; }

.editAdministratorForm_leftDataPicker__2kOhk {
  margin-right: 30px; }

.editAdministratorForm_label__2_sx1 {
  margin-right: 30px; }

.editAdministratorForm_autoprolong__14rAB {
  display: flex;
  margin: 30px 0; }

.editAdministratorForm_autoprolongTitle__1V3ca {
  margin-bottom: 10px; }

.editAdministratorForm_autoprolongRadio__qcnX0 {
  margin-left: 30px; }

.editAdministratorForm_buttonsWrat__sCPcV {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.editAdministratorForm_button__3sFkX {
  margin: 0 10px; }

.selectField_select__3QABM {
  width: 100% !important; }

.selectField_option__2usd2 {
  display: flex;
  justify-content: space-between; }

.selectField_optionName__SQQ5w {
  width: 100% !important; }

.locationTags_wrap__1K6I2 {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  margin-bottom: 30px;
  padding-bottom: 1px; }

.locationTags_tagsView__3b797 {
  border-bottom: 1px solid #e4e6e7;
  padding: 10px; }

.locationTags_selectWrap__3N90I {
  width: 284px;
  margin: 0 auto; }

.locationTags_spinWrap__2O_j- {
  display: flex;
  justify-content: center;
  padding: 24px 10px 18px 10px; }

.edit_wrap__394wi {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.edit_info__3YUCe {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  height: auto; }

.edit_subMenu__1bPlJ {
  margin: 0 auto 10px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  width: 96%; }
  .edit_subMenu__1bPlJ:hover {
    background-color: #eef7ff; }

.edit_subMenuContent__2S_Li {
  padding: 10px;
  width: 98%;
  margin: 0 auto; }

.edit_titleWrap__w921M {
  font-size: 1.5em;
  margin: 0 0 0 22px;
  padding: 20px 0 0 0; }

.edit_addAdmin__1gCmb {
  margin-top: 10px; }

.edit_button__1GW7L {
  margin-top: 20px; }

.locationList_wrap__3D5rk {
  margin: 10px auto 20px auto;
  padding-bottom: 20px;
  width: 96%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.locationList_statistic__2_qtl {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.locationList_listWrap__1dIl7 {
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.locationList_table__323y8 {
  margin-top: 15px; }

.locationListFilter_filter__3wEpV {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  padding: 15px 15px 1px 15px;
  border-radius: 5px; }

.create_wrap__Idx-i {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.create_fromWrap__1bJ3J {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.createOrganizationForm_form__1GZTA {
  width: 480px;
  margin: 25px auto;
  padding: 0 24px; }

.createOrganizationForm_dateFields__2EyXD {
  display: flex;
  justify-content: left; }

.createOrganizationForm_leftDataPicker__3_5GL {
  margin-right: 30px; }

.createOrganizationForm_label__DY9s6 {
  margin-right: 30px; }

.createOrganizationForm_autoprolong__2bSzc, .createOrganizationForm_advertising__1hnWY {
  display: flex;
  margin: 5px 0 10px;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #d9d9d9; }

.createOrganizationForm_autoprolongTitle__3jGAI {
  margin-bottom: 10px; }

.createOrganizationForm_autoprolongRadio__1Asok, .createOrganizationForm_advertisingRadio__172g1 {
  margin-left: 30px; }

.createOrganizationForm_buttonsWrat__2WvM1 {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.createOrganizationForm_button__2Ty7Q {
  margin: 0 10px; }

.create_wrap__13fg6 {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.create_fromWrap__qm5kq {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.createAdministratorForm_form__IR99t {
  width: 480px;
  margin: 25px auto;
  padding: 0 24px; }

.createAdministratorForm_dateFields__3js41 {
  display: flex;
  justify-content: left; }

.createAdministratorForm_leftDataPicker__2LMUS {
  margin-right: 30px; }

.createAdministratorForm_label__3-I7N {
  margin-right: 30px; }

.createAdministratorForm_autoprolong__2dY_U {
  display: flex;
  margin: 30px 0; }

.createAdministratorForm_autoprolongTitle__1dyA6 {
  margin-bottom: 10px; }

.createAdministratorForm_autoprolongRadio__1FHc_ {
  margin-left: 30px; }

.createAdministratorForm_buttonsWrap__1PnP- {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.createAdministratorForm_button__2LkhI {
  margin: 0 10px; }

.confirm_wrap__3ubSj {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.confirm_fromWrap__3ea_q {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.confirmAdministratorForm_form__192pF {
  width: 480px;
  margin: 25px auto;
  padding: 0 24px; }

.confirmAdministratorForm_dateFields__3r-Vp {
  display: flex;
  justify-content: left; }

.confirmAdministratorForm_leftDataPicker__1Wu0w {
  margin-right: 30px; }

.confirmAdministratorForm_label__3soDw {
  margin-right: 30px; }

.confirmAdministratorForm_autoprolong__3J0iK {
  display: flex;
  margin: 30px 0; }

.confirmAdministratorForm_autoprolongTitle__2B9tF {
  margin-bottom: 10px; }

.confirmAdministratorForm_autoprolongRadio__DCUQk {
  margin-left: 30px; }

.confirmAdministratorForm_buttonsWrat__ElGYJ {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.confirmAdministratorForm_button__22KBA {
  margin: 0 10px; }

.create_wrap__26zuv {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.create_fromWrap__2C7DM {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.createLocationForm_form__3hLtk {
  width: 480px;
  margin: 25px auto;
  padding: 0 24px; }

.createLocationForm_dateFields__1V1N9 {
  display: flex;
  justify-content: left; }

.createLocationForm_leftDataPicker__1R7RO {
  margin-right: 30px; }

.createLocationForm_label__1LghI {
  margin-right: 30px; }

.createLocationForm_autoprolong__2QcEx {
  display: flex;
  margin: 30px 0; }

.createLocationForm_autoprolongTitle__2y3qw {
  margin-bottom: 10px; }

.createLocationForm_autoprolongRadio__1X_sv {
  margin-left: 30px; }

.createLocationForm_buttonsWrat__1mStX {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.createLocationForm_button__1XAl0 {
  margin: 0 10px; }

.createLocationForm_divider__2YsST {
  margin: 1px 0; }

.createLocationForm_iconWrap__1odW7 {
  padding: 8px;
  cursor: pointer; }

.createLocationForm_icon__22z3J {
  text-align: center;
  margin: 0 auto; }

.editLocationForm_form__26dGo {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  margin: 10px auto;
  padding: 20px; }

.editLocationForm_dateFields__1VoKK {
  display: flex;
  justify-content: left; }

.editLocationForm_leftDataPicker__6y9hf {
  margin-right: 30px; }

.editLocationForm_label__1TvBn {
  margin-right: 30px; }

.editLocationForm_autoprolong__1yxiC {
  display: flex;
  margin: 30px 0; }

.editLocationForm_autoprolongTitle__3222M {
  margin-bottom: 10px; }

.editLocationForm_autoprolongRadio__14r_e {
  margin-left: 30px; }

.editLocationForm_buttonsWrat__HqYc0 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.editLocationForm_button__3B9Kd {
  margin: 0 10px; }

.editLocationForm_divider__3BPDo {
  margin: 1px 0; }

.editLocationForm_iconWrap__1ei7j {
  padding: 8px;
  cursor: pointer; }

.editLocationForm_icon__SOVVs {
  text-align: center;
  margin: 0 auto; }

.edit_wrap__1APnk {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.edit_info__3KuUr {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  height: auto; }

.edit_subMenu__2tf9B {
  margin: 12px auto 10px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  width: 96%; }
  .edit_subMenu__2tf9B:hover {
    background-color: #eef7ff; }

.edit_subMenuContent__15BVW {
  padding: 10px;
  width: 98%;
  margin: 0 auto; }

.edit_titleWrap__2sbyY {
  font-size: 1.5em;
  margin: 0 0 0 22px;
  padding: 20px 0 0 0; }

.devicesList_wrap__3ridN {
  margin: 10px auto 20px auto;
  padding-bottom: 20px;
  width: 96%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.devicesList_name__1OTbP {
  display: inline;
  margin-left: 10px; }

.devicesList_statistic__3iDBn {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.devicesList_listWrap__2OJ_7 {
  margin-top: 10px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.devicesList_table__QkAW5 {
  margin-top: 15px; }

.devicesList_buttonsWrap__18WGI {
  display: flex;
  width: 224px;
  margin: 0 auto;
  justify-content: space-between; }

.devicesList_buttonActive__sb8M9 {
  margin-left: 4px; }

.devicesList_advertisingWrap__1fOe0 {
  display: flex;
  justify-content: space-between;
  width: 160px;
  margin: 0 auto; }

.devicesList_advertisingStatus__UzMt3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #afb1a9; }

.create_wrap__3RYF8 {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.create_fromWrap__1rnfI {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px; }

.createDeviceForm_form__4DUUH {
  width: 480px;
  margin: 25px auto;
  padding: 0 24px; }

.createDeviceForm_dateFields__3u2vm {
  display: flex;
  justify-content: left; }

.createDeviceForm_leftDataPicker__2-xpX {
  margin-right: 30px; }

.createDeviceForm_label__2-4-g {
  margin-right: 30px; }

.createDeviceForm_autoprolong__2AoV_ {
  display: flex;
  margin: 30px 0; }

.createDeviceForm_autoprolongTitle__yRhOe {
  margin-bottom: 10px; }

.createDeviceForm_autoprolongRadio__1blWa {
  margin-left: 30px; }

.createDeviceForm_buttonsWrat__3xLAE {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.createDeviceForm_button__2RHKm {
  margin: 0 10px; }

.createDeviceForm_timePickerWrap__rPA9H {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: flex-start; }

.createDeviceForm_timePicker__2MXZu {
  margin-right: 20px; }

.checkboxField_checkBoxText__1qoK8 {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1; }

.edit_wrap__37XBg {
  margin: 10px auto;
  width: 90%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.edit_info__21_Xx {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  height: auto; }

.edit_subMenu__pwRZU {
  margin: 12px auto 10px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  width: 96%; }
  .edit_subMenu__pwRZU:hover {
    background-color: #eef7ff; }

.edit_subMenuContent__27vFS {
  padding: 10px;
  width: 98%;
  margin: 0 auto; }

.edit_titleWrap__3Wric {
  font-size: 1.5em;
  margin: 0 0 0 22px;
  padding: 20px 0 0 0; }

.edit_advertisingWrap__2xRcJ {
  width: 640px;
  margin: 0 auto;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between; }

.editDeviceForm_form__3HkLw, .editDeviceForm_checkboxWrap__18RyP {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
  margin: 10px auto;
  padding: 20px; }

.editDeviceForm_dateFields__C-cVc {
  display: flex;
  justify-content: left; }

.editDeviceForm_leftDataPicker__1a48w {
  margin-right: 30px; }

.editDeviceForm_label__MAKjB {
  margin-right: 30px; }

.editDeviceForm_autoprolong__3dKMd {
  display: flex;
  margin: 30px 0; }

.editDeviceForm_autoprolongTitle__3GIA4 {
  margin-bottom: 10px; }

.editDeviceForm_autoprolongRadio__1xkMX {
  margin-left: 30px; }

.editDeviceForm_buttonsWrap__xEWzr {
  margin: 40px 0 20px 0;
  display: flex;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.editDeviceForm_button__fOj0u {
  margin: 0 10px; }

.editDeviceForm_timePickerWrap__30gKe {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: flex-start; }

.editDeviceForm_timePicker__36Zhx {
  margin-right: 20px; }

.locationSelectField_select__2RaTY {
  width: 100% !important; }

.imgWrap {
  margin: 5px 10px 0 0;
  width: 200px; }
  .imgWrap .ant-upload-select-picture-card {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 0; }
  .imgWrap .ant-upload {
    padding: 0px !important;
    margin: 0px !important; }
  .imgWrap .ant-upload-select-picture-card {
    min-width: 120px !important;
    min-height: 180px !important; }

.advertisingConfirm_popover__3bX6r {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 178px; }

.advertisingConfirm_popoverContent__32fw3 {
  margin: 0 auto;
  width: 96%; }

.advertisingConfirm_title__BXkkg {
  text-align: center;
  margin-bottom: 5px; }

.advertisingConfirm_button__25nMS {
  width: 100%; }

.imgWrap {
  margin: 5px 10px 0 0;
  width: 200px; }
  .imgWrap .ant-upload-select-picture-card {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 0; }
  .imgWrap .ant-upload {
    padding: 0px !important;
    margin: 0px !important; }
  .imgWrap .ant-upload-select-picture-card {
    min-width: 120px !important;
    min-height: 180px !important; }

.advertisingWhereEat_popover__1JtwB {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 178px; }

.advertisingWhereEat_popoverContent__3drzG {
  margin: 0 auto;
  width: 96%; }

.advertisingWhereEat_title__9f2bo {
  text-align: center;
  margin-bottom: 5px; }

.advertisingWhereEat_button__2avRa {
  width: 100%; }

.imgWrap {
  margin: 5px 10px 0 0;
  width: 200px; }
  .imgWrap .ant-upload-select-picture-card {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 0; }
  .imgWrap .ant-upload {
    padding: 0px !important;
    margin: 0px !important; }
  .imgWrap .ant-upload-select-picture-card {
    min-width: 120px !important;
    min-height: 180px !important; }

.advertisingAddItemToCart_popover__s5Zt4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 178px; }

.advertisingAddItemToCart_popoverContent__Y5zbh {
  margin: 0 auto;
  width: 100%; }

.advertisingAddItemToCart_title__3NFHz {
  text-align: center;
  margin-bottom: 5px; }

.advertisingAddItemToCart_button__1GRHQ {
  width: 100%; }

