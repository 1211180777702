@import "../../globalStyles/colors.scss";

.footerLayout {
  background-color: $darkslategray;
}

.footer {
  text-align: center;
  font-size: 12px;
  color: $white;
  margin-top: 4px;
}
