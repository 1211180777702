@import '../../../common/globalStyles/colors.scss';

.form {
	box-shadow: $boxShadow;
	margin: 10px auto;
	padding: 20px;
}

.dateFields {
	display: flex;
	justify-content: left;
}

.leftDataPicker {
	margin-right: 30px;
}

.label {
	margin-right: 30px;
}

.autoprolong, .advertising {
	display: flex;
	margin: 5px 0 10px;
	padding: 5px;
	border-radius: 2px;
	border: 1px solid $gray-light;
}

.autoprolongTitle {
	margin-bottom: 10px;
}

.autoprolongRadio, .advertisingRadio {
	margin-left: 30px;
}

.buttonsWrat {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-around;
	width: fit-content;
}

.button {
	margin: 0 10px;
}