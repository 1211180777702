@import '../../globalStyles/colors.scss';

.header {
  background: $darkslategray;
  padding: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 64px;
}

.menu {
  background: $darkslategray;
  line-height: 62px;
  color: $white;
  border-bottom: 0
}

.linkName {
  color: $white;
}