@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';

.wrap {
	margin: 10px auto 20px auto;
	padding-bottom: 20px;
  width: 96%;
	user-select: none;
}

.statistic {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $white;
	box-shadow: $boxShadow;
}

.listWrap {
	margin-top: 10px;
	padding: 20px;
	background-color: $white;
	box-shadow: $boxShadow;
}

.table {
	margin-top: 15px;
}
