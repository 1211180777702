.select {
	width: 100%!important;
	height: 33px;
	overflow: hidden;
}

.option {
	display: flex;
	justify-content: space-between;
}

.optionName {
	width: 100%!important;
}

.buttonsWrap {
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button {
	margin-left: 5px;
}