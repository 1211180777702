@import '../../globalStyles/colors.scss';

.titleWrapper {
  padding: 0 0 15px 0;
}

.title {
  position: relative;
  font-weight: bold;

  &:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100px;
    border-bottom: 2px solid $darkslategray;
  }
}
