.popover {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 178px;
}

.popoverContent {
	margin: 0 auto;
	width: 96%;
}

.title {
	text-align: center;
	margin-bottom: 5px;
}

.button {
	width: 100%;
}