@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';

.wrap {
	margin: 10px auto;
  width: 90%;
	user-select: none;
}

.listWrap {
	margin-top: 10px;
	padding: 20px;
	background-color: $white;
	box-shadow: $boxShadow;
}

.table {
	margin-top: 15px;
}