@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';

.wrap {
	margin: 10px auto;
  width: 90%;
	user-select: none;
}

.info {
	background-color: $white;
	box-shadow: $boxShadow;
	height: auto;
}

.subMenu {
	margin: 12px auto 10px auto;
	box-shadow: $boxShadow;
	width: 96%;
	&:hover {
		background-color: $blue-light;
	}
}

.subMenuContent {
	padding: 10px;
	width: 98%;
	margin: 0 auto;
}

.titleWrap {
	font-size: 1.5em;
	margin: 0 0 0 22px;
	padding: 20px 0 0 0;
}

.advertisingWrap {
	width: 640px;
	margin: 0 auto;
	padding-bottom: 30px;
	display: flex;
	justify-content: space-between;
}